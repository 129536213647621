import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
// import { phoneUKValidator } from '@/modules/registration/validators/registration.validator';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'phone-number-phone',
    components: {},
    data() {
        return {
            userForm: { contactNo: null },
            isSubmitting: false,
            rules: {
                contactNo: [
                    {
                        required: true,
                        message: 'Enter your phone number',
                        trigger: 'blur',
                    },
                ]
            }
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
    },
    async created() {
        const { user } = this;
        await this.getUser(user.id)
            .then((response) => {
            this.userForm.contactNo = response.contactNo;
        });
    },
    methods: {
        ...mapActions(USERS_STORE, ['updateUser', 'getUser']),
        submit() {
            const formData = { ...this.userForm };
            this.isSubmitting = true;
            this.updateUser({ user: formData, userId: this.user.id })
                .then(async (response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Success',
                        message: 'You have successfully selected a role.'
                    });
                    this.$router.push('/setup/terms');
                }
            })
                .catch(() => {
                this.$notify.error({
                    title: 'Error',
                    message: 'There\'s a problem saving data. Please try again.'
                });
            })
                .finally(() => {
                this.isSubmitting = false;
            });
        }
    }
});
